import Configurator from '../../config/Configurator.js';
import axios from 'axios';
import {
    initAlarmDataCache,
    findMissingCoverage,
    fetchAlarmRange,
    storeCoverage,
    getCoverageSubset,
} from '../../utils/AlarmUtils/old.alarms.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';


dayjs.extend(isBetween);

// Cache for storing fetched data
var dataCache = {};


var alarmDataCache = {};

// Function to clear the cache
export function clearCache() {

    dataCache = {}
    alarmDataCache = {}
}

// Function to fetch old data with optional force reload

export async function fetchOldData(
    sensorNum,
    date,
    forceReload = false,
    ship = '',
    sensorMultiplier = null,
    { signal } = {}
) {
    try {
        const dateObj = new Date(date);
        const currentDate = new Date();
        const year = dateObj.getUTCFullYear();
        const month = dateObj.getUTCMonth() + 1;
        let day = dateObj.getUTCDate();

        // Ensure the day is valid
        const lastDayOfMonth = new Date(year, month, 0).getUTCDate();
        if (year === currentDate.getUTCFullYear() && month === currentDate.getUTCMonth() + 1) {
            day = Math.min(day, currentDate.getUTCDate());
        } else {
            day = Math.min(day, lastDayOfMonth);
        }

        const normalizedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const cacheKey = `${sensorNum}-${normalizedDate}`;

        // Initialize cache for sensorNum if it doesn't exist
        if (!dataCache[sensorNum]) {
            dataCache[sensorNum] = [];
        }

        // Check cache and decide on the start date
        let startDate = `${normalizedDate}T00:00:00.000Z`;
        const cachedItem = dataCache[sensorNum].find(item => item.key === cacheKey);

        if (cachedItem && forceReload) {
            const lastEntryTimestamp = cachedItem.data?.x?.[cachedItem.data.x.length - 1];
            if (lastEntryTimestamp) {
                startDate = new Date(lastEntryTimestamp).toISOString();
            }
        } else if (cachedItem && !forceReload) {
            // Return cached data if valid
            if (cachedItem.data.isBoolean) {
                cachedItem.data.y = cachedItem.data.y.map(val => !!val); // Ensure boolean integrity
            }
            return cachedItem.data;
        }

        const endDate = `${normalizedDate}T23:59:59.000Z`;
        const requestBody = {
            serial: ship,
            tag: sensorNum,
            start: startDate,
            end: endDate,
        };

        const config = {
            method: 'post',
            url: (await Configurator()).API_URL + `/v2/io/records/abstract/graph`,
            headers: {
                ...axios.defaults.headers.common,
                'Session': localStorage.getItem('apiKey'),
                'User-Id': localStorage.getItem('userID'),
            },
            data: requestBody,
            maxBodyLength: Infinity,
            signal, 
        };

        const response = await axios.request(config).then(r => r.data).catch(e => {
            if (axios.isCancel(e)) {
                console.log('Request canceled:', e.message);
            } else {
                throw new Error(`HTTP error! Status: ${e}`);
            }
        });

        // Detect if the data contains boolean values
        const isBoolean = typeof response?.data?.y?.[0] === 'boolean';

        if (sensorMultiplier && response.data.y && !isBoolean) {
            response.data.y = response.data.y.map(val => val * sensorMultiplier);
        }

        if (cachedItem && forceReload) {
            // Append new data to the existing cached data, preserving boolean flag
            cachedItem.data = {
                ...cachedItem.data,
                x: [...(cachedItem.data.x || []), ...(response.data.x || [])],
                y: [...(cachedItem.data.y || []), ...(response.data.y || [])].map(val => isBoolean ? !!val : val),
                isBoolean,
            };

            response.data = cachedItem.data;
        } else {
            // Cache the new data
            dataCache[sensorNum].push({
                key: cacheKey,
                data: {
                    ...response.data,
                    isBoolean,
                },
            });
        }

        // Maintain a maximum of 3 cached items per sensor
        if (dataCache[sensorNum].length > 3) {
            dataCache[sensorNum].shift();
        }

        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request was canceled by the user:', error.message);
        } else {
            console.error('Error fetching data:', error);
        }
        return null; // Return null if an error occurs
    }
}



/* 
alarmDataCache = {
  [ship]: {
    [plcNumber]: [
      {
        from: '2023-11-15T00:00:00.000Z',
        to: '2023-11-20T23:59:59.000Z',
        data: { alarmEvents: [ array of alarms ] },
    },
    {
      from: '2023-11-21T00:00:00.000Z',
      to: '2023-11-22T23:59:59.000Z',
      data: [ more alarms  ],
    },
    // ...etc
  ]
}
}
* */




// main fetch function
// main function
export async function fetchOldAlarms(
    plcNumber,
    dateFrom,
    dateTo,
    ship = '',
    forceReload = false
  ) {
    console.log(dateTo,'dateTo')
    const coverage = initAlarmDataCache(ship, plcNumber, alarmDataCache);
    console.log(alarmDataCache,'alarmDataCache')
    // 2) If forceReload, wipe it
    if (forceReload) {
      coverage.length = 0;
    }
  
    // Parse the incoming date strings
    const from = dayjs(dateFrom, "YYYY-MM-DD HH:mm");
    const to   = dayjs(dateTo,   "YYYY-MM-DD HH:mm");
  console.log(from,to,'from,to')
    if (!from.isValid() || !to.isValid()) {
      throw new Error("Invalid date format. Expected 'YYYY-MM-DD HH:mm'");
    }
  
    // 3) Find missing coverage
    const missingIntervals = findMissingCoverage(coverage, from, to);
  
    // 4) Fetch and store coverage for missing intervals
    for (let interval of missingIntervals) {
      const alarmData = await fetchAlarmRange(
        ship,
        plcNumber,
        interval.from,
        interval.to,
        Configurator,
        axios
      );
  
      // storeCoverage merges it into coverage
      storeCoverage(coverage, interval.from, interval.to, alarmData);
    }
  
    // 5) Return all alarms overlapping [from, to] from coverage
    const subset = getCoverageSubset(coverage, from, to);
  
    // For debugging, see what's returned
    console.log('Returning subset:', subset);
  
    return subset;
  }
  