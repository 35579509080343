// components/Sidebar.js
import React, { useEffect } from 'react';
import './Sidebar.css';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { sidebarItems } from '../config/sidebar.items';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarVisible } from '../../services/slices/appConfig';
import { visibleModelsSerials } from '../config/visible.shipmodel.serials';
import ecpIcon from '../../assets/ecp6.png';

function Sidebar({ setIsLoggedIn }) {
  const dispatch = useDispatch();

  const shipSerials = useSelector((state) => state.appConfig.shipSerials);
  const isSidebarVisible = useSelector((state) => state.appConfig.isSidebarVisible);
  const isLargeWindowSidebarVisible = useSelector((state) => state.appConfig.isLargeWindowSidebarVisible);
  const sidebarClass = isSidebarVisible ? 'navbar navbar-open' : 'navbar navbar-closed';
  const navbarLaargeWindowClass = isLargeWindowSidebarVisible ? 'navbar-lg-open' : 'navbar-lg-closed';

  const isVisibleModel = shipSerials.some(model => visibleModelsSerials.includes(model.serial));
  const location = useLocation();
  const navbarClass = location.pathname.includes('simulator')
    ? `${sidebarClass} ${navbarLaargeWindowClass} sidebar-simulator`
    : `${sidebarClass} ${navbarLaargeWindowClass}`;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1100 && isSidebarVisible) {
        dispatch(setSidebarVisible(false));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSidebarVisible, dispatch]);

  const handleNavLinkClick = () => {
    if (window.innerWidth < 1100) {
      dispatch(setSidebarVisible(false));
    }
  };

  // Separate the logout item from the rest.
  const navigationItems = sidebarItems.filter(item => item.path !== '/');
  const logoutItem = sidebarItems.find(item => item.path === '/');

  return (
    <>
      {(isLargeWindowSidebarVisible || isSidebarVisible) && (
        <nav className={navbarClass}>
          <div className="ecp-icon-wrapper2">
            <img className="ecp-icon" src={ecpIcon} alt="ECP Icon" />
            <span className="link-text">Ship Viewer</span>
          </div>
          <ul className="navbar-nav">
            {navigationItems.map((item, index) => {
              if (item.path === '/simulator' && !isVisibleModel) {
                return null;
              }
              return (
                <div className="nav-item-wrapper" key={index}>
                  <li
                    className="nav-item"
                    data-tooltip={item.text} // <-- Add this
                  >
                    <NavLink
                      to={item.path}
                      activeClassName="active"
                      className="nav-link"
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick(setIsLoggedIn);
                        }
                        handleNavLinkClick();
                      }}
                    >
                      {item.icon}
                      <span className="link-text">{item.text}</span>
                    </NavLink>
                  </li>
                </div>
              );
            })}

          </ul>
          {logoutItem && (
            <div className="nav-item-wrapper" >
              <div
                className="logout-wrapper nav-item"
                data-tooltip={logoutItem.text} // <-- add this
              >
                <NavLink
                  to={logoutItem.path}
                  activeClassName="active"
                  className="nav-link"
                  onClick={() => {
                    if (logoutItem.onClick) {
                      logoutItem.onClick(setIsLoggedIn);
                    }
                    handleNavLinkClick();
                  }}
                >
                  {logoutItem.icon}
                  <span className="link-text">{logoutItem.text}</span>
                </NavLink>
              </div></div>
          )}

        </nav>
      )}
    </>
  );
}

export default React.memo(Sidebar);
