// alarmHelpers.js
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';


dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(minMax);

export function initAlarmDataCache(ship, plcNumber, alarmDataCache) {
  if (!alarmDataCache[ship]) {
    alarmDataCache[ship] = {};
  }
  if (!Array.isArray(alarmDataCache[ship][plcNumber])) {
    alarmDataCache[ship][plcNumber] = [];
  }
  return alarmDataCache[ship][plcNumber];
}

export function findMissingCoverage(coverage, from, to) {
  const startBoundary = dayjs(from);
  const endBoundary   = dayjs(to);

  const sortedCoverage = [...coverage].sort(
    (a, b) => dayjs(a.from).valueOf() - dayjs(b.from).valueOf()
  );

  let missingRanges = [];
  let currentStart  = startBoundary;

  for (let interval of sortedCoverage) {
    const intervalStart = dayjs(interval.from);
    const intervalEnd   = dayjs(interval.to);

    // If there is a gap before the interval starts
    if (intervalStart.isAfter(currentStart)) {
      const missingTo = dayjs.min(intervalStart, endBoundary);
      if (missingTo.isAfter(currentStart)) {
        missingRanges.push({
          from: currentStart.toISOString(),
          to: missingTo.toISOString(),
        });
      }
    }

    // Advance currentStart if coverage overlaps
    if (intervalEnd.isAfter(currentStart)) {
      currentStart = intervalEnd;
    }

    // If we've already covered up to `to`, we're done
    if (currentStart.isSameOrAfter(endBoundary)) {
      return missingRanges;
    }
  }

  // If end of coverage is still before `to`, add the final piece
  if (currentStart.isBefore(endBoundary)) {
    missingRanges.push({
      from: currentStart.toISOString(),
      to: endBoundary.toISOString(),
    });
  }

  return missingRanges;
}

// fetchAlarmRange.js
export async function fetchAlarmRange(ship, plcNumber, from, to, Configurator, axios) {
  const dateFrom = dayjs(from).toISOString();
  const dateTo   = dayjs(to).toISOString();

  console.log('Fetching alarms for', ship, plcNumber, dateFrom, dateTo);

  const requestBody = {
    data: {
      transmitterSerial: ship,
      device: plcNumber.toUpperCase(),
      // <--- Use the actual from/to so server returns correct data
      alarmEventOffStartRFC3339: dateFrom,
      alarmEventOffEndRFC3339:   dateTo,
    },
    metadata: {},
  };

  const config = {
    method: 'post',
    url: (await Configurator()).API_URL + `/v2/io/alarms/events/get`,
    headers: {
      ...axios.defaults.headers.common,
      'Session': localStorage.getItem("apiKey"),
      'User-Id': localStorage.getItem("userID"),
    },
    data: requestBody,
    maxBodyLength: Infinity,
  };

  const response = await axios.request(config);
  return response.data;
}


export function storeCoverage(coverage, from, to, alarmData) {
  coverage.push({
    from: dayjs(from).toISOString(),
    to:   dayjs(to).toISOString(),
    // store the entire 'data' object from the server
    data: alarmData.data || {},
  });

  // Merge overlapping intervals
  coverage.sort((a, b) => dayjs(a.from).valueOf() - dayjs(b.from).valueOf());
  let merged = [];

  for (let interval of coverage) {
    if (merged.length === 0) {
      merged.push(interval);
    } else {
      const last      = merged[merged.length - 1];
      const lastEnd   = dayjs(last.to);
      const currStart = dayjs(interval.from);

      if (currStart.isSameOrBefore(lastEnd)) {
        // Overlap: merge the alarmEvents
        const newData = interval.data.alarmEvents || [];
        if (!last.data.alarmEvents) {
          last.data.alarmEvents = [];
        }
        last.data.alarmEvents = [...last.data.alarmEvents, ...newData];

        // Expand "to" if needed
        const currEnd = dayjs(interval.to);
        if (currEnd.isAfter(lastEnd)) {
          last.to = currEnd.toISOString();
        }
      } else {
        merged.push(interval);
      }
    }
  }

  coverage.splice(0, coverage.length, ...merged);
}

/**
 * Return all alarms that overlap [from, to].
 */
// getCoverageSubset.js
export function getCoverageSubset(coverage, from, to) {
  const startBoundary = dayjs(from);
  const endBoundary   = dayjs(to);

  let results = [];

  coverage.forEach(interval => {
    // Check if the coverage interval itself overlaps
    const intervalStart = dayjs(interval.from);
    const intervalEnd   = dayjs(interval.to);

    if (intervalEnd.isAfter(startBoundary) && intervalStart.isBefore(endBoundary)) {
      console.log('Interval', interval, interval.to, 'overlaps');
      // For each alarm in that interval
      interval.data.alarmEvents.forEach(alarm => {
        const alarmOn  = dayjs(alarm.onTs);
        const alarmOff = dayjs(alarm.offTs);

        // Does the alarm overlap [startBoundary, endBoundary]?
        if (alarmOn.isBefore(endBoundary) && alarmOff.isAfter(startBoundary)) {
          results.push(alarm);
        }
      });
    }
  });

  return {
    data: results,
    metadata: {}, // if needed
  };
}
