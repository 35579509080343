// appConfigSlice.js
import { createSlice } from '@reduxjs/toolkit';

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: {
    isSidebarVisible: false,
    ledLive: false,
    shipSerials: [],
    neededSensors: {},
    isLargeWindowSidebarVisible: true,
    importedIcons: [],
    lockState:{}
  },
  reducers: {
    setSidebarVisible: (state, action) => {
      state.isSidebarVisible = action.payload;
    },
    setLargeWindowSidebarVisible: (state, action) => {
      state.isLargeWindowSidebarVisible = action.payload;
    },
    setLedLive: (state, action) => {
      state.ledLive = action.payload;
    },
    setShipSerials: (state, action) => {
      state.shipSerials = action.payload;
    },
    setNeededSensors: (state, action) => {
      console.log("Setting needed sensors:", action.payload);
      state.neededSensors = action.payload;
    },
    addNeededSensors: (state, action) => {  
      console.log("Before Adding needed sensors:", JSON.parse(JSON.stringify(state.neededSensors)), action.payload);
    
      Object.entries(action.payload).forEach(([ship, sensors]) => {
        state.neededSensors[ship] = state.neededSensors[ship]
          ? [...new Set([...state.neededSensors[ship], ...sensors])] // Merge & remove duplicates
          : sensors;
      });
    
      console.log("After Adding needed sensors:", JSON.parse(JSON.stringify(state.neededSensors)));
    },
    setLockState: (state, action) => {
      state.lockState = action.payload  
    }
    ,
    // Actions to manage imported icons:
    setImportedIcons: (state, action) => {
      state.importedIcons = action.payload;
    },
    addImportedIcons: (state, action) => {
      state.importedIcons = [...state.importedIcons, ...action.payload];
    },

    removeImportedIcon: (state, action) => {
      state.importedIcons = state.importedIcons.filter(
        (icon) => icon.filename !== action.payload
      );
    },
  },
});

export const {
  setSidebarVisible,
  setLargeWindowSidebarVisible,
  setLedLive,
  setShipSerials,
  setNeededSensors,
  addNeededSensors,
  setImportedIcons,
  addImportedIcons,
  setLockState,
  removeImportedIcon,
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
