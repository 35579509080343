import Configurator from "../../config/Configurator";
import axios from "axios";

export const fetchMeta = async (configName,setNeededSensors, endpoint = '/v2/db/sqlite/tables/meta/get_one' ) => {
    var userID = localStorage.getItem("userID");

    try {
        let config = {
            method: 'post',
            url: (await Configurator()).API_URL + endpoint,
            headers: {
                ...axios.defaults.headers.common,
                ...{
                    'Session': localStorage.getItem("apiKey"),
                    'User-Id': localStorage.getItem("userID"),
                }
            },
            data: JSON.stringify({
                "entity_id": parseInt(userID),
                "entity_table": "users",
                "name": configName,
            })
        };
        const response = await axios.request(config);
        return response.data.data; 
    } catch (error) {
        if (error.response.data.status.errCode === 3 && setNeededSensors) {
            setNeededSensors('error')
        }
        return {}; 
    }
};